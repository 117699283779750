<template>
 <div id="app">
    <!-- 右侧大的页面 -->
   
    <div >
      <div>{{title  }}</div>
      <div>{{title2  }}</div>
      <div class ="container">
       
      <div class="cc">
      <label class="custom-file-upload">
      <span>选择文档</span>
      <input id="fileInput" type="file" accept = ".pdf" @change="onFileChange" name="111" value=""/>
      </label>
      </div>
      <div class="button-container">
      <el-button @click="uploadFile" size="medium">开始转换</el-button>
      </div>
      </div>
      
      

      <div style="float: left; width: calc(100% - 100px);">

      <el-table
      v-if="show"
      id="stable"
      :data="tableData"
      :show-header=false
      style="width: 100%">
      <el-table-column
        prop="filename"
        label="文件名"
        width="180">
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        width="180">
      </el-table-column>      
      <el-table-column
      fixed="right"
      label="操作"
      width="100"
      >
      <template v-slot="scope">
        <el-button v-if="opShow" @click="handleClick(scope.row)" type="text" size="small">下载</el-button>        
      </template>
    </el-table-column>

    </el-table>
  
  </div>
 
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { inject } from 'vue';
export default {
  mounted() {
    // 检查子组件是否挂载
    this.onInit();
  },
 
  setup() {
    // 接收名为 'message' 的数据
    const url666 = inject('url666');
    const urlBackend = inject('urlBackend');
    const apiToImage = inject('apiToImage');
    return {
      url666,
      urlBackend,
      apiToImage
    };},
  data() {
   
  
    return {
      
      title:"PDF转图片",
      title2:"把PDF转成图片，输出格式为jpg",
   
      treeData: [
        {
          id: 1,
          
          label: 'PDF转换',
          children: [
            {
              id: 2,
              
              label: 'PDF转图片'              
            },
            {
              id: 5,
              label: 'PDF转Word'
            },
            {
              id: 6,
              label: 'PDF转Excel'
            },
            {
              id: 7,
              label: 'PDF转PPT'
            },
          ]
        },
        {
          id: 8,
          label: 'PDF工具',
          children: [
            {
              id: 9,
              label: 'PDF拆分'             
            },
            {
              id: 12,
              label: 'PDF合并'
            }
          ]
        }
      ],
      opShow:false,
      show:false,
      tableData: [{            
          }],
      nodeKey:"id",
      fileeData:null,
      timer:null,
      tm:0,
      uid:"0"
    };
  },
  durl:"",
  name: 'HelloWorld',
  props: {
    msg: String
  },
  methods: {
    open(){
      document.cookie ='selected=other; path=/';
      this.$alert('<form >\
  <label>\
    <input type="radio" name="option" value="option1" onclick="document.cookie =\'selected=zhihu; path=/\'"   > 知乎\
  </label><br>\
  <label>\
    <input type="radio" name="option" value="option2" onclick="document.cookie =\'selected=xiaohongshu; path=/\'" > 小红书\
  </label><br>\
  <label>\
    <input type="radio" name="option" value="option3" onclick="document.cookie =\'selected=baidu; path=/\'"> 百度\
  </label><br>\
  <label>\
    <input type="radio" name="option" value="option4" onclick="document.cookie =\'selected=pengyou; path=/\'"> 朋友推荐\
  </label><br>\
  <label>\
    <input type="radio" name="option" value="option5" onclick="document.cookie =\'selected=weixin; path=/\'"> 微信\
  </label><br>\
  <label>\
    <input type="radio" name="option" value="option6" onclick="document.cookie =\'selected=other; path=/\'"> 其他\
  </label><br>\
  <label>\
    <input type="radio" name="option" value="option7" checked onclick="document.cookie =\'selected=secret; path=/\'"> 保持神秘\
  </label>\
</form>', '你从哪里来，我的朋友？', {
          dangerouslyUseHTMLString: true
        }).then(()=>{
          const url = this.urlBackend + "/source"    
          const value = this.getCookie('selected')   
          axios.post(url, {'option':value}).then(response => {
        // 处理服务器响应
        
        console.log('服务段已经接收通知')     
        console.log(response)
      }).catch(error => {
        // 处理错误
        console.error(error);
        
      });

          }
        ).
        catch(action =>{
          console.log(action);  
          const url = this.urlBackend + "/source"            
          axios.post(url, {'option':'none'})
        }
        )
      
    },
    
    setCookie(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  },
  getCookie(name) {
      let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name + "=([^;]*)"
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    
    onInit(){
      
      const visited = this.getCookie('visited')      
      if(!visited){
          this.open()
      }
        this.setCookie('visited','123','100')
        this.uid = this.getCookie('uid')
        if(!this.uid){
        this. uid = Math.random().toString(36).substr(2, 9);
        this.setCookie('uid',this.uid,'100')
      }
    },
    handleClick(row){
        const url = this.urlBackend + "/download"    
          
          axios.post(url, {'url':this.durl}).then(response => {
        // 处理服务器响应
        
        console.log('服务段已经接收通知')     
        console.log(response)
      }).catch(error => {
        // 处理错误
        console.error(error);
        
      });
      console.log(row)
      window.open(this.durl);
      
    },
    getPrompt(){
      
      if(this.tableData[0].filesize > 1000 * 1024 * 1024)
        return "已处理"+this.tm +"秒，请耐心等待,预计不超过15分钟"
      if(this.tableData[0].filesize > 150 * 1024 * 1024)
        return "已处理"+this.tm +"秒，请耐心等待,预计不超过5分钟"      
      return "已处理"+this.tm +"秒，请耐心等待,预计不超过2分钟"      
    },
        uploadFile() {
          
          if(!this.fileData){
            this.$message({
              type: 'error',
              message: "请先选择需要上传的文档"
            });
             return;
          }
          const formData = new FormData();
      formData.append('file', this.fileData);
      formData.append('uid', this.uid);
       
       const url = this.urlBackend + this.apiToImage
       this.tm = 2
       this.timer = setInterval(() => {
        this.tableData[0].status = this.getPrompt()
        this.tm = this.tm + 1
    }, 1000); 

       this.tableData[0].status=this.getPrompt()
       this.tableData[0].filename=this.fileData.name        
       this.show = true      
       this.opShow = false
      axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {
        // 处理服务器响应
        if (this.timer) {
          clearInterval(this.timer);
          this.timer=null
        }
        this.durl = this.url666 +response.data
        console.log(this.durl);
        console.log("okkk")
        this.opShow = true
        this.tableData[0].status="转换成功"        
      }).catch(error => {
        // 处理错误
        if (this.timer) {
          clearInterval(this.timer);
          this.timer=null
        }
        console.error(error);
        this.tableData[0].status="转换失败，请稍后再试"+error.message
      });
    }
        ,
        onFileChange(event) {
          if(this.timer){
            this.$message({
              type: 'error',
              message: "正在转换中，请不需要上传其他文档"
            });
            return;
          }
      const file = event.target.files[0];
      if (file) {
       this.fileData = file;
      }
      this.tableData[0].status="已选择"
       this.tableData[0].filename=this.fileData.name 
       this.tableData[0].filesize = this.fileData.size
       this.show = true      
    },
        handleNodeClick(nodeData, node, component) {
      console.log('点击了：', nodeData,node,component);
      this.number="66666";
      
    }
  
      }
    }
      
  
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  /* 自定义file类型的input */
  #fileInput {
    display: none;
  }

  .custom-file-upload {
    color: #fff;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-color: #0960bd;
    background: #0960bd;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    border-radius: 4px;
  }
  .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh; /* 使用视口高度 */
}

.button-container {
  margin: 0 10px; /* 可以根据需要调整 */
}

.cc {
  margin: 0 10px; /* 可以根据需要调整 */
}

  .custom-file-upload span {
    margin-right: 8px;
  }


h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
